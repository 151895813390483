import { GET_ROUTES_SUCCESS, GET_ROUTES_LOADING, GET_ROUTES_ERROR } from "../../constants/routes/routes";

const menuReducers = (state = [], action) => {
    switch (action.type) {
        case GET_ROUTES_SUCCESS:
            const menuLeft = action.menuLeft;
            return {
                ...state,
                menuLeft,
                isLoading: false
            }
        case GET_ROUTES_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case GET_ROUTES_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                menuLeft: []
            }
        default:
            return state;
    }
}

export default menuReducers;

export const getRoutesSuccess = state => state.users;
export const getRoutesLoading = state => state.isLoading;
export const getRoutesError = state => state.error;