import React, { useState, Fragment, Suspense, useEffect } from "react";
// import { useSelector } from 'react-redux';
import { history } from "../../utilities/helpers/index";
import { useDispatch } from "react-redux";
import { profileService } from "../../utilities/services/user/profile/profile.service";
import { profileAction } from "../../actions/profile-actions";
import { routeService } from "../../utilities/services/routes/route.service";
import { TWO_FACTOR_AUTHENTICATION } from '../../constants';
import { cacheService } from '../../utilities/services';

import "../../index.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
import "../../assets/css/sb-admin-2.min.css";
import "../../assets/css/themify-icons.css";
// import "./assets/css/material-design-icons.scss";

const Dashboard = React.lazy(() => import("./Dashboard"));

const Layout = () => {
  const [userProfile, setUserProfile] = useState({});
  const [menuLeft, setMenuLeft] = useState([]);

  const dispatch = useDispatch();
  const loadMenuLeft = async () => {
    const response = await routeService.getMenuLeft();
    if (response) {
      setMenuLeft(response);
    }
  }
  const getUserInfo = async () => {
    const response = await profileService.getUserInfo();
    if (response) {
      dispatch(profileAction.getUserInfo(response.data));
      setUserProfile(response.data);
    } else {
      dispatch(profileAction.getUserInfoFailed(response));
    }
  }

  useEffect(() => {
    getUserInfo();
    loadMenuLeft();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //
  const twoFactorAuthentication = cacheService.get(TWO_FACTOR_AUTHENTICATION);
  if (!userProfile || !twoFactorAuthentication) {
    history.push("/login");
  }
  return (
    <Fragment>
      <Suspense
        fallback={
          <div style={{ top: "47%", left: "47%", position: "fixed" }}>
            Đang tải...
          </div>
        }
      >
        {<Dashboard data={menuLeft} />}
      </Suspense>
    </Fragment>
  );
}

export default Layout;
