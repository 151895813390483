import CryptoJS from "crypto-js";
import { AES_IV } from "../../../constants/index";

const paginationOptions = (
  { page, prePage, nextPage,
    firstPage, lastPage, className,
    noDataText }) => {
  return {
    page: page || 1,
    prePage: prePage || '<',
    nextPage: nextPage || '>',
    firstPage: firstPage || 'Trang đầu',
    lastPage: lastPage || 'Trang cuối',
    className: className || 'pagination-table',
    noDataText: noDataText || 'Không tìm thấy dữ liệu'
  }
}

const normalizeString = (str) => {
  if (!str) { return '' }
  return str
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
    .toLowerCase()
    .trim();
}

const removeSpaces = (str) => {
  // Gộp nhiều dấu space thành 1 space
  str = str.replace(/\s+/g, '');
  // loại bỏ toàn bộ dấu space (nếu có) ở 2 đầu của xâu
  str.trim();
  return str;
}

const addZero = (num) => {
  if (num < 10 & num.toString().length < 2) {
    num = "0" + num;
  }
  return num;
}

const generateIV = () => (Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2)).substring(0, 16);


/*
* Encrypt a derived hd private key with a given pin and return it in Base64 form
*/
const encryptAES = (text, iv) => {
  return CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY), { iv: CryptoJS.enc.Utf8.parse(iv) }).toString();
};


/**
 * Decrypt an encrypted message
 * @param encryptedBase64 encrypted data in base64 format
 * @param key The secret key
 * @return The decrypted content
 */
const decryptAES = (encryptedBase64) => {
  if (encryptedBase64) {
    const decrypted = CryptoJS.AES.decrypt(encryptedBase64, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY), { iv: CryptoJS.enc.Utf8.parse(AES_IV) });
    if (decrypted) {
      try {
        const str = decrypted.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          return JSON.parse(str);
        } else {
          return -1;
        }
      } catch (e) {
        return e;
      }
    }
    return -3;
  }
  else return null;
};

export default { paginationOptions, normalizeString, generateIV, removeSpaces, addZero, encryptAES, decryptAES }
