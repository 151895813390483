
function error(message, title, delay = null) {
  return {
    type: 'ERROR',
    message,
    title,
    delay
  }
}
function success(message, title, delay = null) {
  return {
    type: 'SUCCESS',
    message,
    title,
    delay
  }
}
function information(message, title, delay = null) {
  return {
    type: 'INFORMATION',
    message,
    title,
    delay
  }
}

function hide() {
  return {
    type: 'HIDE'
  }
}
export default {
  error,
  success,
  information,
  hide
}