import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// import backgroundImg from "../../assets/images/login-background.jpg";
// import logoLoginImg from "../../assets/images/logo.png";
import { history } from "../../utilities/helpers/index";
import { loginActions } from "../../actions/login-actions";
import tokenService from "../../utilities/services/token/token.service";
import authenticateService from "../../utilities/services/authenticate/authenticate.service";
import { cacheService } from "../../utilities/services";
import { AES_IV, LOCAL_STORAGE_USER_INFO, ROOT, TWO_FACTOR_AUTHENTICATION } from "../../constants/index";
import loadingAction from "../../actions/loading/loading.action";
// import Loading from "../../commons/components/loading/Loading";

import { sendAuthorizationRequest, sendTokenRequest } from "./actions/sign-in";
import { isValidSession, getAllSessionParameters, decodeIdToken } from "./actions/session";
import { dispatchLogout } from "./actions/sign-out";
import utilsService from "../../utilities/services/utils/utils.service";
// import ReactJson from 'react-json-view';


export class LoginResponse {
  accessToken;
  encryptedAccessToken;
  expireInSeconds;
  userId;
}
const Login = () => {
  // const [inputs, setInputs] = useState({
  //   username: "",
  //   password: "1q2w3E*",
  // });
  // console.log("process.env.REACT_APP_SSO", JSON.stringify(process.env.REACT_APP_SSO));

  // const [submitted, setSubmitted] = useState(false);
  const [invalidPass, setInvalidPass] = useState(false);
  const [accepted, setAccepted] = useState(false);

  // sso
  // const [ssoState, setSsoState] = useState({
  //   idToken: {},
  //   tokenResponse: {},
  //   isLoggedIn: false
  // });
  // end sso

  // const { username, password } = inputs;
  const dispatch = useDispatch();
  // const sidebarBackground = {
  //   backgroundImage: "url(" + backgroundImg + ")",
  //   backgroundRepeat: "no-repeat",
  //   backgroundPosition: "center center",
  //   backgroundSize: "cover",
  // }

  // const inputStyle = {
  //   borderRadius: "0 .35rem .35rem 0",
  // }

  // function handleChange(e) {
  //   const { name, value } = e.target;
  //   setInputs((inputs) => ({ ...inputs, [name]: value }));
  // }

  function base64ToArray(base64String) {
    var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    var base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
    var rawData = window.atob(base64);

    return rawData;
  }

  async function handleSubmit(sub) {
    // console.log("sub", sub)
    // e.preventDefault();
    // setSubmitted(true);
    if (sub) {
      authenticateService
        .authenticate(sub, "1q2w3E*")
        .then((result) => {
          // console.log("result", result);
          // eslint-disable-next-line eqeqeq
          if (result == 202) {
            setAccepted(true);
            // return;
          }
          tokenService.storeToken(result.accessToken);

          // console.log("storeToken", utilsService.encryptAES(result.accessToken, AES_IV))
          const tokenDecode = decodeURIComponent(
            // escape(atob(result.accessToken?.split(".")[1]))
            escape(base64ToArray(result.accessToken?.split(".")[1]))
          );
          dispatch(loginActions.login(tokenDecode));
          cacheService.set(LOCAL_STORAGE_USER_INFO, utilsService.encryptAES(tokenDecode, AES_IV));
          // console.log("tokenDecode", JSON.parse(tokenDecode));
          // console.log("result.accessToken", result.accessToken);
          if (sub === ROOT || JSON.parse(tokenDecode)?.twoFactorEnabled !== "True") {
            cacheService.set(TWO_FACTOR_AUTHENTICATION, true);
            history.push("/admin/home");
          }
          else {
            authenticateService.sendMail();
            window.location.href = "/admin/verify"; //k cập nhật chỗ này thành history.push vì lỗi đọc currentUser
          }
        })
        .catch((err) => {
          console.log("err", err)
          dispatch(loginActions.loginFailed(err));
          dispatch(loadingAction.close());
          setInvalidPass(true);
        });
    }
  }

  // /**
  //    * Handles login button click
  //    */
  // const handleLoginBtnClick = () => {
  //   sendAuthorizationRequest();
  // };

  /**
   * Handles logout button click
   */
  const handleLogoutBtnClick = () => {
    console.log("logout");
    localStorage.clear();
    // history.push("/login");
    dispatchLogout();
  };


  useEffect(() => {
    let state = {
      tokenResponse: "",
      idToken: "",
      isLoggedIn: false
    };

    if (isValidSession()) {
      const session = getAllSessionParameters();
      const _tokenResponse = {
        access_token: session.ACCESS_TOKEN,
        refresh_token: session.REFRESH_TOKEN,
        scope: session.SCOPE,
        id_token: session.ID_TOKEN,
        token_type: session.TOKEN_TYPE,
        expires_in: parseInt(session.EXPIRES_IN),
      };

      state.tokenResponse = _tokenResponse;
      state.idToken = decodeIdToken(session.ID_TOKEN);
      state.isLoggedIn = true;
      // setSsoState(state);

      // console.log("isValidSession", state);
      handleSubmit(state.idToken.sub);

      // setSsoState({
      //   tokenResponse: _tokenResponse,
      //   idToken: decodeIdToken(session.ID_TOKEN),
      //   isLoggedIn: true
      // });
      return;
    }

    // Reads the URL and retrieves the `code` param.
    const code = new URL(window.location.href).searchParams.get("code");

    // If a authorization code exists, sends a token request.
    if (code) {
      sendTokenRequest(code)
        .then(response => {
          console.log("TOKEN REQUEST SUCCESS", response);
          state.tokenResponse = response[0];
          state.idToken = response[1];
          state.isLoggedIn = true;
          // setSsoState(state);
          // setSsoState({
          //   tokenResponse: response[0],
          //   idToken: response[1],
          //   isLoggedIn: true
          // })
          // console.log("sendTokenRequest", state)

          handleSubmit(state.idToken.sub);
        })
        .catch((error => {
          console.log("TOKEN REQUEST ERROR", error);
          state.isLoggedIn = false;
          // setSsoState(state);
          // setSsoState({ isLoggedIn: false });
        }));
    } else {
      // console.log("state.isLoggedIn", state)
      if (!state.isLoggedIn)
        sendAuthorizationRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div style={{ backgroundColor: "rgb(223, 223, 223)" }}>

      {!invalidPass & !accepted ?
        <div id="full-layout-loader"></div>
        :
        <div id="react-root">
          <div
            style={{
              width: 400,
              position: 'absolute',
              left: '50%',
              marginLeft: '-200px',
              textAlign: 'center',
              padding: '10px 100px',
              border: '1px solid rgb(221, 221, 221)',
              borderRadius: 20,
              background: 'rgb(239, 239, 239)',
              top: '20%',
              fontWeight: 200,
              fontSize: 15,
              color: 'rgb(68, 68, 68)',
              fontFamily: '"Open Sans", Helvetica, Arial, sans-serif'
            }}
          >
            <h5 style={{ fontWeight: 400, fontSize: 20 }}>Error 403 : Forbidden</h5>
            <br />
            <p>{accepted ? "IP chưa được cấp quyền truy cập hệ thống." : "Tài khoản chưa được cấp quyền để truy cập tài nguyên hệ thống."}</p>
            {/* <button type="button" style={{ padding: '5px 15px', margin: 20, borderRadius: 5, textTransform: 'uppercase', color: 'rgb(21, 184, 207)', border: '1px solid rgb(21, 184, 207)', background: 'rgb(239, 239, 239)' }}>Retry</button> */}
            <button
              type="button"
              style={{ padding: '5px 15px', margin: 20, borderRadius: 5, textTransform: 'uppercase', color: 'rgb(0, 0, 0)', background: 'rgb(21, 184, 207)' }}
              onClick={() => handleLogoutBtnClick()}
            >Đăng xuất</button>
          </div>
        </div>

      }


    </div>
    // <div style={sidebarBackground}>
    //   {
    //     ssoState.isLoggedIn ?
    //       <>
    //         <br />
    //         <h2>Token Response</h2>
    //         <div className="card access-request-block">
    //           <ReactJson src={ssoState.tokenResponse} collapseStringsAfterLength={50} />
    //         </div>
    //         <br />
    //         <h2>ID Token</h2>
    //         <div className="card token-request-block">
    //           <ReactJson src={ssoState.idToken} collapseStringsAfterLength={50} />
    //         </div>
    //         <br />
    //         <button className="btn btn-danger" onClick={() => handleLogoutBtnClick()}>LOGOUT</button>
    //       </>
    //       :
    //       <></>
    //   }
    //   <div className="login-header">
    //     <div className="content-header ">
    //       <div className="col-lg-12  left-header-content">
    //         <img src={logoLoginImg} alt="" />
    //         <h3
    //           style={{
    //             letterSpacing: ".5px",
    //           }}
    //         >
    //           HỆ THỐNG THÔNG TIN NGUỒN CÀ MAU
    //         </h3>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="login-content">
    //     <div className="login-content-body">
    //       <p className="login-title">Đăng nhập hệ thống</p>
    //       <form name="form" onSubmit={handleSubmit}>
    //         <div className="form-group">
    //           <div className="login-group-input">
    //             <i className="login-icon-user ti-user"></i>
    //             <input
    //               placeholder="Tên Đăng nhập"
    //               style={inputStyle}
    //               type="text"
    //               name="username"
    //               value={username}
    //               onChange={handleChange}
    //               className={
    //                 "form-control" +
    //                 (submitted && !username ? " is-invalid" : "")
    //               }
    //             />
    //           </div>
    //           <div className="login-error">
    //             {submitted && !username && <div>Tên đăng nhập là bắt buộc</div>}
    //           </div>
    //         </div>
    //         <div className="form-group">
    //           <div className="login-group-input">
    //             <i className="login-icon-user  ti-lock"></i>
    //             <input
    //               placeholder="Mật khẩu"
    //               style={inputStyle}
    //               type="password"
    //               name="password"
    //               value={password}
    //               onChange={handleChange}
    //               className={
    //                 "form-control" +
    //                 (submitted && !password ? " is-invalid" : "")
    //               }
    //             />
    //           </div>
    //           <div className="login-error">
    //             {submitted && !password && <div>Mật khẩu là bắt buộc</div>}
    //             {submitted && invalidPass && (
    //               <div>{accepted ? "Thiết bị chưa được cấp quyền truy cập hệ thống" : "Tên đăng nhập hoặc mật khẩu không đúng"}</div>
    //             )}
    //           </div>
    //         </div>
    //         <div className="login-group-btn form-group">
    //           <button className="btn-login btn btn-primary">Đăng nhập</button>
    //         </div>
    //       </form>
    //     </div>
    //   </div>

    //   <div className="login-footer">
    //     <div className="content-footer">
    //       <div className="col-lg-12">
    //         <p className="m-0">© Sở Thông tin và Truyền thông tỉnh Cà Mau </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Login;
