import { lazy } from "react";

const DashBoard = lazy(() => import("../../pages/admin/home/DashBoard"));
//administrator
const Menu = lazy(() => import("../../pages/admin/system-management/menu/Menu"));
const AccountManagement = lazy(() => import("../../pages/admin/user-info/AccountManagement"));
const UserRole = lazy(() => import("../../pages/admin/user-info/UserRole"));
const ChangePassword = lazy(() => import("../../pages/admin/user-info/ChangePassword"));
const DeviceConfigurationProfiles = lazy(() => import("../../pages/admin/device-configuration-profiles/DeviceConfigurationProfiles"));
const UserLog = lazy(() => import("../../pages/admin/user-logs/UserLogs"));
const ConnectedDevices = lazy(() => import("../../pages/admin/connected-devices-management/ConnectedDevicesManagement"));
const BackupConfigurationProfiles = lazy(() => import("../../pages/admin/system-management/backup-management/BackupConfigurationProfiles"));
const BackupDiary = lazy(() => import("../../pages/admin/system-management/backup-management/BackupDiary"));

//categories
const GeoDistrict = lazy(() => import("../../pages/admin/administrative-unit/geo-district/GeoDistrict"));
const GeoCommune = lazy(() => import("../../pages/admin/administrative-unit/geo-commune/GeoCommune"));
const IntegratedUnit = lazy(() => import("../../pages/admin/integrated-unit/IntegratedUnit"));
const RadioChannel = lazy(() => import("../../pages/admin/radio-management/radio-channel/RadioChannel"));

//news
const NewsField = lazy(() => import("../../pages/admin/news-management/news-field/FieldList"));

//radio
const ConvertSchedule = lazy(() => import("../../pages/admin/radio-management/conversion-device-schedule/ConvertSchedule"));
const ConversionDevicesManagement = lazy(() => import("../../pages/admin/radio-management/conversion-device-management/ConversionDevicesManagement"));

const RadioNewsManagement = lazy(() => import("../../pages/admin/radio-management/radio-news-management/RadioNewsManagement2"));
const StatitonManagement = lazy(() => import("../../pages/admin/radio-management/station-management/StationManagement"));
const DevicesManagement = lazy(() => import("../../pages/admin/radio-management/devices-management/DevicesManagement"));
const RadioEquipmentLog = lazy(() => import("../../pages/admin/radio-management/devices-management-logs/RadioEquipmentLog"));


//statistical
const RadioEquipmentChart = lazy(() => import("../../pages/admin/radio-management/radio-statistical/RadioEquipmentChart"));
const RadioNewsChart = lazy(() => import("../../pages/admin/radio-management/radio-statistical/RadioNewsDiaryChart"));
const RadioStationChart = lazy(() => import("../../pages/admin/radio-management/radio-statistical/RadioStationChart"));

//electronic message board
const ElectronicMessageBoardEquipment = lazy(() => import("../../pages/admin/electronic-message-board/equipment"));
const ElectronicMessageBoardNews = lazy(() => import("../../pages/admin/electronic-message-board/news/ElectronicMessageBoardNews"));

const NewsDiaryFrontEnd = lazy(() => import("../../layouts/frontend-template/Views/RadioNewsDiarys/NewsDiary"));

//blank layout
const WebviewRadioStatisticalEquipment = lazy(() => import("../../pages/webview/radio-management/RadioEquipmentChart"));


export const CONFIGROUTES = [
  { url: "/admin/home", component: DashBoard },
  // { url: "/admin/articles/fields", component: NewsManagement },
  // { url: "/admin/articles/news", component: ContentsManagement },
  // { url: "/admin/systems/department", component: Department },

  //administrator
  { url: "/admin/users/roles", component: UserRole },
  { url: "/admin/users/account", component: AccountManagement },
  { url: "/admin/users/logs", component: UserLog },
  { url: "/admin/connected-devices", component: ConnectedDevices },
  { url: "/admin/systems/menu", component: Menu },
  { url: "/admin/change-password", component: ChangePassword },
  { url: "/admin/systems/device-configuration-profiles", component: DeviceConfigurationProfiles },
  { url: "/admin/systems/backup-configuration-profiles", component: BackupConfigurationProfiles },
  { url: "/admin/systems/backup-diary", component: BackupDiary },

  //categories
  { url: "/admin/categories/district", component: GeoDistrict },
  { url: "/admin/categories/commune", component: GeoCommune },
  { url: "/admin/categories/fieldlist", component: NewsField },
  { url: "/admin/categories/radio-channel", component: RadioChannel },
  { url: "/admin/categories/integrated-unit", component: IntegratedUnit },

  //convert
  { url: "/admin/conversion-device/manage", component: ConversionDevicesManagement },
  { url: "/admin/conversion-device/schedule", component: ConvertSchedule },

  //radio
  { url: "/admin/radio/post-management", component: RadioNewsManagement },
  { url: "/admin/radio/station-management", component: StatitonManagement },
  { url: "/admin/radio/devices-management", component: DevicesManagement },
  { url: "/admin/radio/devices-logs", component: RadioEquipmentLog },

  //radio statistical
  { url: "/admin/radio-statistical/equipment", component: RadioEquipmentChart },
  { url: "/admin/radio-statistical/news", component: RadioNewsChart },
  { url: "/admin/radio-statistical/station", component: RadioStationChart },

  //electronic message board
  { url: "/admin/electronic-message-board/devices", component: ElectronicMessageBoardEquipment },
  { url: "/admin/electronic-message-board/news", component: ElectronicMessageBoardNews },

  //trang ngoài
  { url: "/NewsDiary", component: NewsDiaryFrontEnd },

  //plank
  { url: "/webview/radio-statistical/equipment", component: WebviewRadioStatisticalEquipment },
];

export const GET_ROUTES_SUCCESS = "GET_ROUTES_SUCCESS";
export const GET_ROUTES_LOADING = "GET_ROUTES_LOADING";
export const GET_ROUTES_ERROR = "GET_ROUTES_ERROR";
