import proxyService from "../proxy/proxy.service";
import utilsService from "../utils/utils.service";
// import cryptoJS from "crypto-js";
// import Util from "../utils/utils.service";
const authenticateService = {
  authenticate,
  sendMail,
  checkCode
}
const useDummy = false;
async function authenticate(username, password) {
  if (useDummy) {
    return proxyService.getJSON("/assets/dummy/authenticate.json").then(async result => result.data.result);
  }
  const iv = utilsService.generateIV();

  const result = await proxyService.post("/api/TokenAuth/Authenticate", {
    username,
    password: utilsService.encryptAES(password, iv)
  }, { iv });
  // console.log("authenticate", result)
  // eslint-disable-next-line eqeqeq
  if (result.status == 202)
    return 202;
  return result.data.result;
}

async function sendMail() {
  return await proxyService.get("/api/TokenAuth/SendMail");
}

async function checkCode(code) {
  return await proxyService.get(`/api/TokenAuth/CheckCode?code=${code}`);
}

export default authenticateService