
function open() {
  return {
    type: 'OPEN_LOADING',
    isLoading: true
  }
}
function close() {
  return {
    type: 'CLOSE_LOADING',
    isLoading: false
  }
}
export default {
  open,
  close
}