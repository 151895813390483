export const GET_MENU = "";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_FAILED = "GET_USER_INFO_FAILED";

export const LOCAL_STORAGE_TOKEN = "token";
export const LOCAL_STORAGE_USER_INFO = "user_info";
export const TWO_FACTOR_AUTHENTICATION = "two_factor_authentication";
export const ROOT = "root";

export const AES_IV = "l74l88cj04owzzjo";
export const DATATABLE_MODAL_CREATE_MODE = "DATATABLE_MODAL_CREATE_MODE";
export const DATATABLE_MODAL_UPDATE_MODE = "DATATABLE_MODAL_UPDATE_MODE";
