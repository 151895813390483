import React, { useEffect } from "react";

import { sendAuthorizationRequest, sendTokenRequest } from "./actions/sign-in-mobile";
import { isValidSession, getAllSessionParameters, decodeIdToken } from "./actions/session";

export class LoginMobile {
  accessToken;
  encryptedAccessToken;
  expireInSeconds;
  userId;
}
const Login = () => {

  useEffect(() => {
    let state = {
      tokenResponse: "",
      idToken: "",
      isLoggedIn: false
    };

    if (isValidSession()) {
      const session = getAllSessionParameters();
      const _tokenResponse = {
        access_token: session.ACCESS_TOKEN,
        refresh_token: session.REFRESH_TOKEN,
        scope: session.SCOPE,
        id_token: session.ID_TOKEN,
        token_type: session.TOKEN_TYPE,
        expires_in: parseInt(session.EXPIRES_IN),
      };

      state.tokenResponse = _tokenResponse;
      state.idToken = decodeIdToken(session.ID_TOKEN);
      state.isLoggedIn = true;
      // setSsoState(state);

      // console.log("isValidSession", state);
      // handleSubmit(state.idToken.sub);
      let object = {
        Username: state.idToken.sub,
      }
      // console.log("object", JSON.stringify(object));
      window.ReactNativeWebView.postMessage(JSON.stringify(object));
      return;
    }

    // Reads the URL and retrieves the `code` param.
    const code = new URL(window.location.href).searchParams.get("code");

    // If a authorization code exists, sends a token request.
    if (code) {
      sendTokenRequest(code)
        .then(response => {
          console.log("TOKEN REQUEST SUCCESS", response);
          state.tokenResponse = response[0];
          state.idToken = response[1];
          state.isLoggedIn = true;

          // handleSubmit(state.idToken.sub);
          let object = {
            Username: state.idToken.sub,
          }
          // console.log("object", JSON.stringify(object));
          window.ReactNativeWebView.postMessage(JSON.stringify(object));
        })
        .catch((error => {
          console.log("TOKEN REQUEST ERROR", error);
          state.isLoggedIn = false;
          // setSsoState(state);
          // setSsoState({ isLoggedIn: false });
        }));
    } else {
      // console.log("state.isLoggedIn", state)
      if (!state.isLoggedIn)
        sendAuthorizationRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div style={{ backgroundColor: "rgb(223, 223, 223)" }}>
        <div id="full-layout-loader">
          <a id="logout" href="/logout" hidden>Đăng xuất</a>
        </div>
    </div>
  );
}

export default Login;
