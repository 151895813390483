import socketIOClient from "socket.io-client";
import { SCHEDULE_CONVERT_STOP, SCHEDULE_CONVERT_START } from "../../../constants/type-socket";

var socket;

const connect = (callback) => {
  socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
    // transports: ["websocket"],
    jsonp: false,
    allowUpgrades: false,
    // pingTimeout: 30000,
  });

  socket.on("connect", function () {
    console.log("connected socket!!!");
    callback();
  });

  socket.on("disconnect", function () {
    console.log("server disconnected socket!!!");
    // socket.off("new-problem");
  });
};

const disconnect = () => {
  socket.disconnect();
  console.log("client disconnected socket!!!");
};

const testSocket = (callback) => {
  socket.on("testsocket", function (data) {
    // console.log("testSocket", data);
  });
};

const getMac = (json_data) => {
  return {
    macAddress: json_data.macAddress,
    error: json_data.error,
  };
};
const connectDevicesManagement = (callback) => {
  let parseData;
  socket.on("systemInfo/receive", (data) => {
    parseData = JSON.parse(data);
    let infoData = JSON.parse(parseData.value) || "";
    let value = {
      ...getMac(parseData),
      ram: infoData?.ram?.split("/") || "",
      disk: (infoData?.disk + "/")?.split("G/") || "",
      cpuTemperature: infoData.cpuTemp || "",
      uptime: infoData.uptime || "",
      portSSH: infoData.portSSH || "",
      statusRemoteIt: infoData.statusRemoteIt || false
    };
    callback({ ...value, type: "systemInfo" });
  });
  socket.on("volume/receive", (data) => {
    parseData = JSON.parse(data);
    callback({ ...parseData, ...getMac(parseData), volume: parseData.value, type: "volume" });
  });
  socket.on("relay/receive", (data) => {
    parseData = JSON.parse(data);
    callback({ ...parseData, ...getMac(parseData), relay: parseData.value, type: "relay" });
  });
  socket.on("sound/receive", (data) => {
    parseData = JSON.parse(data);
    callback({ ...parseData, ...getMac(parseData), sound: parseData.value, type: "sound" });
  });
  socket.on("sim/receive", (data) => {
    parseData = JSON.parse(data);
    callback({ ...parseData, ...getMac(parseData), simStatus: parseData.value, type: "sim" });
  });
  socket.on("restart/receive", (data) => {
    parseData = JSON.parse(data);
    callback({ ...parseData, ...getMac(parseData), restart: parseData.value, type: "restart" });
  });
  socket.on("remote/receive", (data) => {
    parseData = JSON.parse(data);
    callback({ ...parseData, ...getMac(parseData), simStatus: parseData.value, type: "remote" });
  });
  socket.on("schedule/receive", (data) => {
    parseData = JSON.parse(data);
    callback({ ...parseData, ...getMac(parseData), type: "schedule" });
  });
  socket.on("devices/status", (data) => {
    // console.log("devices/status", data)
    //parseData = JSON.parse(data);
    callback({ ...data, type: "status" });
  });
  socket.on("MQTT_DISCONNECT", (data) => {
    // parseData = JSON.parse(data);
    callback({ ...data, type: "MQTT_DISCONNECT" });
  });
};


const disconnectDevicesManagement = () => {
  socket.off("systemInfo/receive");
  socket.off("volume/receive");
  socket.off("relay/receive");
  socket.off("sound/receive");
  socket.off("sim/receive");
  socket.off("restart/receive");
  socket.off("schedule/receive");
  socket.off("devices/status");

};

const radioNewsManagement = (callback) => {
  // socket.on(SCHEDULE_CREATE, (data) => callback({ type: SCHEDULE_CREATE, data }));
  // socket.on(SCHEDULE_UPDATE, (data) => callback({ type: SCHEDULE_UPDATE, data }));
  // socket.on(SCHEDULE_DELETE, (data) => callback({ type: SCHEDULE_DELETE, data }));
  // socket.on(SCHEDULE_START, (data) => callback({ type: SCHEDULE_START, data }));
  // socket.on(SCHEDULE_STOP, (data) => callback({ type: SCHEDULE_STOP, data }));
  socket.on(SCHEDULE_CONVERT_START, (data) => callback({ type: SCHEDULE_CONVERT_START, data }));
  socket.on(SCHEDULE_CONVERT_STOP, (data) => callback({ type: SCHEDULE_CONVERT_STOP, data }));
};
const disconnectRadioNewsManagement = (callback) => {
  // socket.off(SCHEDULE_CREATE)//, (data) => callback({ type: SCHEDULE_CREATE, data }));
  // socket.off(SCHEDULE_UPDATE)//, (data) => callback({ type: SCHEDULE_UPDATE, data }));
  // socket.off(SCHEDULE_DELETE)//, (data) => callback({ type: SCHEDULE_DELETE, data }));
  // socket.off(SCHEDULE_START)//, (data) => callback({ type: SCHEDULE_START, data }));
  // socket.off(SCHEDULE_STOP)//, (data) => callback({ type: SCHEDULE_STOP, data }));
  socket.off(SCHEDULE_CONVERT_START)//, (data) => callback({ type: SCHEDULE_START, data }));
  socket.off(SCHEDULE_CONVERT_STOP)//, (data) => callback({ type: SCHEDULE_STOP, data }));
};

export const SocketHelper = { connect, testSocket, connectDevicesManagement, disconnectDevicesManagement, radioNewsManagement, disconnectRadioNewsManagement, disconnect };
