import React, { useEffect } from "react"
import { Router, Switch, Route } from "react-router-dom"
import indexRoutes from "./commons/routes/index"
import { PrivateRoute } from "./commons/routes/private-routes"
import LoginPage from "./pages/login/Login"
import LoginMobile from "./pages/login/LoginMobile"
import LogoutMobile from "./pages/login/LogoutMobile"
import VerifyPage from "./pages/login/Verify"
import FrontEndLayout from './layouts/frontend-template/Layout';
import { configureStore } from "./store/store"
import { Provider } from "react-redux"
import { history } from "./utilities/helpers/history"
import Loading from "./commons/components/loading/Loading"
import SnackBar from "./commons/components/snack-bar/SnackBar"
import { SocketHelper } from './utilities/helpers/socket-helper/index';

import "./App.css"

const App = () => {

  useEffect(() => {
   
    SocketHelper.connect(() => {
      SocketHelper.testSocket();
    });
    return () => {
      SocketHelper.disconnect();
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider store={configureStore()}>
      <Router history={history}>
        
        <Loading />
        <SnackBar />
        {window.location.pathname === "/" ? <Switch>
            <Route path="/" component={FrontEndLayout} />
        </Switch> :
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/login-mobile" component={LoginMobile} />
            <Route path="/logout" component={LogoutMobile} />
            <Route path="/admin/verify" component={VerifyPage} />
            {indexRoutes.map((prop, key) => {
              return <PrivateRoute path={prop.path} key={key} component={prop.component} />
            })}
          </Switch>}
      </Router>
      
    </Provider>
  )
}

export default App
