import { createStore, applyMiddleware } from "redux"
import { createLogger } from "redux-logger"
import rootReducers from "../reducers/rootReducers"
import interceptorService from "../utilities/services/proxy/interceptor.service"
const logger = createLogger()

export function configureStore(initialState) {
  let middleWares = []
  // eslint-disable-next-line eqeqeq
  if (process.env.REACT_APP_TARGET_ENV != "prod") {
    middleWares = [logger]
  }

  const store = createStore(rootReducers, initialState, applyMiddleware(...middleWares))

  interceptorService.interceptorService(store)

  return store
}
