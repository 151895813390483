import React from "react";
import Toast from "react-bootstrap/Toast";
import { useSelector, useDispatch } from "react-redux";
import snackBarActions from "../../../actions/snack-bar/snackBar.action";
const SnackBar = () => {
  const snackBar = useSelector(state => state.snackBarReducer);
  const dispatch = useDispatch();
  return (
    <Toast
      onClose={() => { dispatch(snackBarActions.hide()) }}
      autohide={!!snackBar.delay}
      delay={snackBar.delay}
      className={"snack-bar " + (snackBar.snackBarType || "")}
      show={snackBar.isShow}
      animation={false} >
      <Toast.Header>
        <strong className="mr-auto">{snackBar.title}</strong>
      </Toast.Header>
      {snackBar.message &&
        <Toast.Body>
          <div dangerouslySetInnerHTML={{ __html: snackBar.message }}></div>
        </Toast.Body>
      }
    </Toast >
  );
}
export default SnackBar;