import { SOCKET_DATA_STORE } from "../../constants/type-socket"

const actionsTypes = {
  setData: SOCKET_DATA_STORE
}

const initialState = {
  data: {}
}

const socketReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.setData:
      return {
        ...state,
        data: action.data
      }
    default:
      return {
        ...state
      }
  }
}

export default socketReducer
