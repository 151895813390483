const INIT_STATE = {
  menuGroup: [
    { id: '1', name: "Quản trị hệ thống", code: "red" },
    { id: '2', name: "Quản lý bài viết", code: "green" },
    { id: '3', name: "Thông tin người dùng", code: "black" },
    { id: '4', name: "Thông tin chính quyền", code: "blue" }
  ],
  menuType: [
    { id: '1', label: "Menu Trái", value: "menuLeft" },
    { id: '2', label: "Menu Phải", value: "menuRight" }
  ],
  parentMenu: [
    {
      key: '1',
      label: 'Bảng điều khiển',
      data: 'dashboard',
      children: [],
    },
    {
      key: '2',
      label: 'Quản trị hệ thống',
      data: 'sysManagement',
      children: [
        {
          key: '213Q2',
          label: 'Quản lý menu',
          data: 'menu',
          children: []
        },
        {
          key: '23232',
          label: 'Quản lý chức vụ',
          data: 'title',
          children: []
        },
        {
          key: '23232',
          label: 'Quản lý đơn vị',
          data: 'org',
          children: []
        }
      ]
    }
  ],
  functions: [
    {
      key: '1',
      label: 'Quản lý bài viết',
      data: 'postManagement',
      children: [],
    },
    {
      key: '2',
      label: 'Quản lý số liệu phân tích',
      data: 'sysManagement',
      children: [
        {
          key: '213Q2',
          label: 'Mobile',
          data: 'menu',
          children: [
            {
              key: '123',
              label: 'Dữ liệu báo cáo',
              data: 'reportData',
              children: []
            }
          ]
        },
        {
          key: '23232',
          label: 'Web',
          data: 'title',
          children: [
            {
              key: '1233',
              label: 'Thông báo khẩn',
              data: 'urgebbt',
              children: []
            }
          ]
        }
      ]
    }
  ],
  description: '',
  link: '',
  activation: false,
  code: '',
  name: '',
  icon: '',
  displayType: [{
    key: '1',
    label: 'Mobile',
    data: 'mobile',
    children: [ {
      key: '3243243',
      label: 'Thông báo chung',
      data: 'generalNoti',
      children: []
    },
    {
      key: '23232',
      label: 'Thông báo cập nhật',
      data: 'updatedNoti',
      children: []
    },],
  },
  {
    key: '2',
    label: 'Web',
    data: 'web',
    children: [
      {
        key: '34',
        label: 'Tin tức',
        data: 'menu',
        children: []
      },
      {
        key: '123',
        label: 'Danh sách ngang',
        data: 'title',
        children: []
      }
    ]
  }],
  location: 0,
  values: {}
}

const formFieldReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'GET_FORM_DATA':
      return {
        ...state
      }
    case 'SAVE_FORM_DATA':
      const formVal = Object.assign(state.values, action.payload)
      return {
        ...state,
        values: formVal
      }
    default:
      return state;
  }
}

export default formFieldReducers;