import AdminLayout from '../../layouts/admin-panel/Layout';
import FrontEndLayout from '../../layouts/frontend-template/Layout';
import BlankLayout from '../../layouts/blank-layout/Layout';
import LoginPage from '../../pages/login/Login';

var indexRoutes = [
    { path: '/webview', name: 'BlankLayout', component: BlankLayout },
    { path: '/admin', name: 'AdminLayout', component: AdminLayout },
    { path: "/login", name: "Login", component: LoginPage },
    { path: '/', name: 'FrontEndLayout', component: FrontEndLayout },
    
];

export default indexRoutes;