import React, { useEffect } from "react";
import { dispatchLogout } from "./actions/sign-out-mobile";

const Logout = () => {

  /**
   * Handles logout button click
   */
  const handleLogoutBtnClick = () => {
    // console.log("logout");
    localStorage.clear();
    // history.push("/login");
    dispatchLogout();
  };


  useEffect(() => {
    handleLogoutBtnClick()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div style={{ backgroundColor: "rgb(223, 223, 223)" }}>
      <div id="full-layout-loader">
      </div>
    </div>
  );
}

export default Logout;
