import React, { Fragment, Suspense, useState } from "react";
import { cacheService } from '../../utilities/services';
import { LOCAL_STORAGE_USER_INFO } from '../../constants';
import tokenService from "../../utilities/services/token/token.service";
import authenticateService from "../../utilities/services/authenticate/authenticate.service";
// import utilsService from "../../utilities/services/utils/utils.service";
const Dashboard = React.lazy(() => import("./Dashboard"));

const Layout = () => {
  const currentUser = cacheService.get(LOCAL_STORAGE_USER_INFO);
  const [statusLogin, setstatusLogin] = useState(currentUser !== null);
  // const twoFactorAuthentication = cacheService.get(TWO_FACTOR_AUTHENTICATION);
  // console.log('user', currentUser)
  if (!currentUser) {
    authenticateService
      .authenticate("nguoidungchuadangnhap", "1q2w3E*")
      .then((result) => {
        tokenService.storeToken(result.accessToken);
        setstatusLogin(true)
      })
      .catch((err) => {
      });
  }

  return (
    <Fragment>
      <Suspense
        fallback={
          <div>
            
          </div>
        }
      >
        {statusLogin && <Dashboard />}
      </Suspense>
    </Fragment>
  );
};

export default Layout;
