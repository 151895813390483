import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LOCAL_STORAGE_TOKEN, TWO_FACTOR_AUTHENTICATION } from '../../constants';
import { cacheService } from '../../utilities/services';

export const PrivateRoute = ({ component: Component, ...rest }) => (


    <Route {...rest} render={props => {
        const path = window.location.pathname;
        const currentUser = cacheService.get(LOCAL_STORAGE_TOKEN);
        const twoFactorAuthentication = cacheService.get(TWO_FACTOR_AUTHENTICATION);
        if ((!currentUser || !twoFactorAuthentication) && path.includes("/admin")) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
        // authorised so return component
        return <Component {...props} />
    }} />
)

export default PrivateRoute;