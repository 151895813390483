import React, { useState, Suspense } from "react";
// import backgroundImg from "../../assets/images/login-background.jpg";
import backgroundImg from "../../assets/images/HTTTN-CM-1-BG-1920x1080-text.png";

// import logoLoginImg from "../../assets/images/logo.png";
import { history } from "../../utilities/helpers/index";
import authenticateService from "../../utilities/services/authenticate/authenticate.service";
import { cacheService } from "../../utilities/services";
import { TWO_FACTOR_AUTHENTICATION, LOCAL_STORAGE_USER_INFO } from "../../constants/index";
import utilsService from "../../utilities/services/utils/utils.service";
import { dispatchLogout } from "../../pages/login/actions/sign-out";

const ImportCSS = React.lazy(() => import("./ImportCSS"));
const currentUser = utilsService.decryptAES(cacheService.get(LOCAL_STORAGE_USER_INFO));
// console.log("currentUser", currentUser);
export default () => {
  const [code, setCode] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [failed, setFailed] = useState(false);

  const sidebarBackground = {
    backgroundImage: "url(" + backgroundImg + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  }

  function handleChange(e) {
    const { value } = e.target;
    setCode(value);
  }

  const sendMail = () => {
    authenticateService.sendMail();
  }
  const handleCheckCode = () => {
    setSubmitted(true);
    if (!code) return;
    authenticateService
      .checkCode(code)
      .then((result) => {
        // console.log('result check code', result);
        cacheService.set(TWO_FACTOR_AUTHENTICATION, true);
        history.push("/admin/home");
      })
      .catch((err) => {
        // dispatch(loginActions.loginFailed(err));
        // dispatch(loadingAction.close());
        setFailed(true);
      });
  }
  const logOut = () => {
    localStorage.clear();
    // history.push("/login");
    dispatchLogout();
  }

  // useEffect(() => {
  //   let state = {
  //     tokenResponse: "",
  //     idToken: "",
  //     isLoggedIn: false
  //   };

  //   if (isValidSession()) {
  //     const session = getAllSessionParameters();
  //     const _tokenResponse = {
  //       access_token: session.ACCESS_TOKEN,
  //       refresh_token: session.REFRESH_TOKEN,
  //       scope: session.SCOPE,
  //       id_token: session.ID_TOKEN,
  //       token_type: session.TOKEN_TYPE,
  //       expires_in: parseInt(session.EXPIRES_IN),
  //     };

  //     state.tokenResponse = _tokenResponse;
  //     state.idToken = decodeIdToken(session.ID_TOKEN);
  //     state.isLoggedIn = true;
  //     return;
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <div style={sidebarBackground}>
      <div className="login-header">
        <div className="content-header ">
          {/* <div className="col-lg-12 left-header-content">
            <img src={logoLoginImg} alt="" />
            <h3
              style={{
                letterSpacing: ".5px",
              }}
            >
              HỆ THỐNG THÔNG TIN NGUỒN TỈNH CÀ MAU
            </h3>
          </div> */}
        </div>
      </div>
      <div className="login-content">
        <div className="login-content-body">
          <p className="login-title">Xác thực email</p>
          <div className="form-group">
            Mã xác thực được gửi đến địa chỉ:
            <span className="text-primary"> {currentUser?.email ? currentUser.email[0] : ""}</span>
            <div className="input-group">
              <input
                type="text"
                aria-label="Nhập mã xác thực"
                aria-describedby="basic-addon2"
                placeholder="Nhập mã xác thực"
                name="code"
                value={code}
                onChange={handleChange}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    handleCheckCode()
                  }
                }}
                className={
                  "form-control" +
                  (submitted && !code ? " is-invalid" : "")
                }
              />
              <div className="input-group-append">
                <button className="btn btn-outline-secondary" type="button" onClick={() => sendMail()}>Gửi lại</button>
              </div>
            </div>
            <div className="login-error">
              {submitted && !code && <div>Mã bắt buộc</div>}
              {failed && <div>Mã xác thực không đúng hoặc đã hết hạn.</div>}
            </div>
          </div>
          <div className="login-group-btn form-group">
            <button className="btn-login btn btn-primary" onClick={() => handleCheckCode()}>Xác thực</button>
          </div>
          <button className="btn btn-link" onClick={() => logOut()}><i className="fa fa-arrow-left"></i> Đăng nhập với tài khoản khác!</button>
        </div>
      </div>

      <div className="login-footer">
        <div className="content-footer">
          {/* <div className="col-lg-12">
            <p className="m-0">© Sở Thông tin và Truyền thông tỉnh Cà Mau </p>
          </div> */}
        </div>
      </div>
      <Suspense fallback={<div id="full-layout-loader"></div>}>
        <ImportCSS />
      </Suspense>
    </div>
  );
}
